

export default {
    props: [],
    name: 'Rgpd',
    components: { },
    data: function () {
      return {
        questions: [
          {
            answer: this.$t('¿Qué es la RGPD?'),
            responses: [
                this.$t('El nuevo Reglamento General de Protección de Datos (RGPD), será aplicable en todos los Estados miembros a partir del 25 de mayo de 2018') + '.' + this.$t('Este nuevo Reglamento tiene como objetivo proteger los derechos y libertades de las personas físicas por lo que se refiere al tratamiento de sus datos personales') + '.',
                '<br><br>',
                this.$t('El nuevo reglamento hace equivalente el nivel de protección a todos los estados de la Unión Europea') + '.' + this.$t('Es decir, si tu empresa está almacenando y procesando datos personales de residentes en la UE, tendrás que hacer una serie de cambios') + '.' + this.$t('Entre los más importantes destacan: demostrar que has obtenido y gestionas los datos de forma lícita, tendrás que realizar evaluaciones de impacto de la privacidad regularmente y tendrás que tomar medidas para impedir vulnerabilidades en los datos obtenidos, entre otras muchas') + '.',
            ],
          },
          {
            answer: this.$t('¿A qué datos hace referencia?'),
            responses: [
              this.$t('El Reglamento Europeo se centra, principalmente en los datos de personas físicas') + '.' + this.$t('Estos datos se clasifican en dos categorías') + ':',
              '<br><br>',
              this.$t('a) Datos personales Toda información sobre una persona física identificada o identificable («el interesado»)') + '.' + this.$t('Se considerará persona física identificable toda persona cuya identidad pueda determinarse, directa o indirectamente, mediante un identificador, como por ejemplo') + ':',
              '<br>',
              '<ul>',
                '<li>' + this.$t('Nombre y apellidos') + '</li>',
                '<li>' + this.$t('Número de identificación') + '</li>',
                '<li>' + this.$t('Datos de localización') + '</li>',
                '<li>' + this.$t('Un identificador online') + '</li>',
                '<li>' + this.$t('Uno o varios elementos propios de la identidad física, fisiológica, genética, psíquica, económica, cultural o social de dicha persona') + '</li>',
              '</ul>',
              '<br><br>',
              this.$t('b) Datos personales sensibles') + ':',
              '<br>',
              '<ul>',
                '<li>' + this.$t('Origen racial o étnico') + '</li>',
                '<li>' + this.$t('Opiniones políticas') + '</li>',
                '<li>' + this.$t('Creencias religiosas o filosóficas') + '</li>',
                '<li>' + this.$t('Afiliación sindical') + '</li>',
                '<li>' + this.$t('Los datos genéticos') + '</li>',
                '<li>' + this.$t('Datos biométricos con el objetivo de identificar de manera exclusiva a un individuo') + '</li>',
                '<li>' + this.$t('Aquellos datos relativos a la salud o la vida sexual y/o la orientación sexual') + '</li>',
              '</ul>',
            ],
          },
          {
            answer: this.$t('¿Quién está obligado a cumplir con la RGPD?'),
            responses: [
                this.$t('El RGPD se aplica a todas las empresas o personas físicas que procesan datos personales de residentes en la UE y que están establecidas en la UE') + '.' + this.$t('Además, el Reglamento amplía su alcance, y afecta también a empresas que ofrecen bienes o servicios a residentes en la UE, así como a empresas que monitorizan y siguen el comportamiento de residentes en la UE') + '.',
                '<br><br>',
                this.$t('Desde 360NRS te recomendamos que tu proveedor de servicios de SMS, e-mail y cualquier tipo de comunicaciones esté en la Unión Europea, como en nuestro caso') + '.' + this.$t('De otra forma, tendrás que exigir a este proveedor que nombre un representante en este territorio, que actuará como punto de contacto con las autoridades de control y los residentes') + '.',
            ],
          },
          {
            answer: this.$t('¿Qué ha cambiado?'),
            responses: [
              '<ol>',
                '<li>' + this.$t('El ámbito de aplicación se amplía ') + '</li>',
                '<li>' + this.$t('Adiós al consentimiento tácito, la manifestación del consentimiento debe ser explícita (implicar un acto afirmativo evidente) e inequívoca ') + '</li>',
                '<li>' + this.$t('Las condiciones que rigen el consentimiento deben ser distintas a las de otras condiciones generales ') + '</li>',
                '<li>' + this.$t('No se puede solicitar la aceptación del consentimiento como una condición previa a un registro, este consentimiento ha de ser inequívoco ') + '</li>',
                '<li>' + this.$t('Se prohíbe expresamente la utilización de casillas de verificación activadas por defecto ') + '</li>',
                '<li>' + this.$t('Exige el uso de opciones de consentimiento diferentes para cada procedimiento ') + '</li>',
                '<li>' + this.$t('Se garantiza el derecho específico de anular un consentimiento ') + '</li>',
                '<li>' + this.$t('Los ciudadanos podrán ejercer el “derecho al olvido” directamente ante el organismo responsable del tratamiento de datos ') + '</li>',
              '</ol>',
            ],
          },
          {
            answer: this.$t('Las sanciones'),
            responses: [
                this.$t('Esta normativa viene con un aumento de las sanciones') + '.' + this.$t('Para empezar, contempla indemnizaciones a personas que hayan sufrido daños materiales o no materiales por una vulnerabilidad en el tratamiento de sus datos') + '.',
                '<br><br>',
                this.$t('Además, las autoridades nacionales encargadas de la supervisión, tendrán la potestad de imponer multas que pueden alcanzar, según la calificación de la infracción, entre 10 y 20 millones de euros o, en el caso de una empresa, del 2 % al 4 % de la facturación anual mundial, la mayor cuantía retenida') + '.',
            ],
          },
          {
            answer: this.$t('¿Cómo podemos ayudarte?'),
            responses: [
                this.$t('Una de las novedades de la RGPD tiene que ver con la obtención del consentimiento, que tiene que ser explícita y afirmativa') + '...' + this.$t('es decir, si estabas enviando comunicaciones a tu base de datos porque había una relación contractual previa o habías obtenido un consentimiento tácito') + '...' + this.$t('¡Tienes que volver a solicitar el consentimiento antes del 25 de mayo!'),
                '<br><br>',
                this.$t('Pero no te agobies, tenemos una buena noticia para ti') + ':',
                '<br><br>',
                this.$t('Hemos desarrollado un sistema de formularios para que puedas solicitar por e-mail o SMS a toda tu base de datos su consentimiento para recibir comunicaciones') + '.',
            ],
          },
        ],
      }
    },
}
